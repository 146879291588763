import { observer } from "mobx-react";
import React from "react";
import BookCollectionView from "../components/cabinet/BookCollectionView";
import CabinetTopView from "../components/cabinet/CabinetTopView";
import EnsureLoginedView from "../components/cabinet/EnsureLoginedView";
import CabinetLayout from "../components/cabinet/CabinetLayout";
import SEO from "../components/seo";

const BooksPage = observer(() => {
	return (
		<CabinetLayout>
			<SEO title="Books"
			     description="Tribal Books is a free-to-play online digital collectible nft-card game"/>

			<EnsureLoginedView>
				<CabinetTopView title="My Books"
				                desc="Ancient civilizations created symbols to store their most important knowledge and descriptions of powerful magic in the sacred books of forces. Those books are the most priceless treasures for every man of the tribe. Assets from the Ancient Symbols Collection have two major advantages: they are limited, they are universal. Your own tribal book makes the magical forces of it serve to you only."
				/>
				<BookCollectionView/>
			</EnsureLoginedView>
		</CabinetLayout>
	);
});

export default BooksPage;